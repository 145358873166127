<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";

export default {
  page: {
    title: "XIVAPI Laravel Docs",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: {
    Layout,
  },
  data: function() {
    return {
      result: '',
    };
  }
};
</script>

<template>
  <Layout>
    <div class="row align-items-center">
        <div class="col-sm-6">
            <div class="page-title-box">
                <h4 class="page-title">XIVAPI Laravel Docs</h4>
                <ol class="breadcrumb mb-0">
                    <li class="breadcrumb-item">XIVDATA</li>
                    <li class="breadcrumb-item">XIVAPI Laravel</li>
                    <li class="breadcrumb-item active">Home</li>
                </ol>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-6 col-lg-6 col-xl-2">
                            <div class="card">
                                <div class="nav flex-column nav-pills">
                                    <router-link to="/xivapi" class="nav-link"><i class="fas fa-home"></i> Home</router-link>
                                    <router-link to="/xivapi/release" class="nav-link"><i class="far fa-user"></i> Release Information</router-link>
                                    <router-link to="/xivapi/search" class="nav-link"><i class="far fa-envelope"></i> Search</router-link>
                                    <router-link to="/xivapi/character" class="nav-link"><i class="fas fa-horse"></i> Character</router-link>
                                    <router-link to="/xivapi/freecompany" class="nav-link"><i class="fas fa-cat"></i> Free Company</router-link>
                                    <router-link to="/xivapi/linkshell" class="nav-link"><i class="fas fa-trophy"></i> Linkshell</router-link>
                                    <router-link to="/xivapi/content" class="nav-link"><i class="fas fa-images"></i> Content</router-link>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-5 col-lg-5 col-xl-6">
                            <h1>XIVAPI Laravel Client</h1>
                            <p>This provides a very simple client to interact with the XIVAPI and obtain dynamic objects in return.</p>
                            <div>
                                <img src="https://img.shields.io/packagist/dt/xivdata/xivapi-laravel" alt="Packagist Downloads" class="img-fluid text-center" />
                                <img src="https://github.com/XIVDATA/xivapi-laravel/workflows/Build/badge.svg?branch=master" alt="Build Status" class="img-fluid text-center" />
                            </div>
                            <br>
                            <h2>Getting Started</h2>
                            <p>This library requires PHP 7.3+<br>
                            Install via <a href="https://packagist.org/packages/xivdata/xivapi-laravel" target="_blank" class="text-white">Composer</a>:</p>
                            <kbd style="color: black;">
                                composer require xivdata/xivapi-laravel
                            </kbd>
                            <p>&nbsp;</p>
                            <h3>What it does</h3>
                            <p>The <code>xivapi-laravel</code> package is a very simple libary to access XIVAPI.</p>
                            <h3>Usage Example</h3>
                            <pre>
                                <code>
                                    &lt;?php

                                    namespace App\Http\Controllers;

                                    use Illuminate\Http\Request;
                                    use XIVAPI\XIVAPI;

                                    class Example extends Controller
                                    {
                                        public function foo()
                                        {
                                            $api = new XIVAPI();

                                            $result = $api->character->get('9065567', [$data = 'MIMO']);

                                            dd($result);
                                        }
                                    }
                                </code>
                            </pre>
                            <h4>Setting your key if you have one</h4>
                            <p>You can set the environment variable: <code>XIVAPI_KEY</code></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </Layout>
</template>